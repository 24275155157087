<template>
  <table class="lg:w-3/4 w-full my-4">
    <tr class="w-full" v-for="(item, index) in data.rows" :key="index">
      <td class="align-top lg:w-1/5 w-5/12" v-html="item.heading"/>
      <td class="align-top">
        <text-block :data="item.content"/>
      </td>
    </tr>
  </table>
</template>

<script>
import TextBlock from '@/components/blocks/TextBlock.vue'

export default {
  name: 'TableBlock',
  props: ['data'],
  components: {
    TextBlock
  }
}
</script>